<template>
  <BaseListPage locale-section="pages.carvers" route="carver">
    <CarversList />
  </BaseListPage>
</template>

<script>
export default {
  name: "BracteateTypesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    CarversList: () => import("./CarversList")
  }
};
</script>
